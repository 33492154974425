import React, { Suspense } from "react";
import { useRoutes } from "react-router-dom";

// Components
import Root from "./Root";

// Home
const Home = React.lazy(() => import('../pages/Home'));

// Landings
const Index2 = React.lazy(() => import('../pages/landings/Index2'));
const Trial = React.lazy(() => import('../pages/landings/trial'));
const Oferta = React.lazy(() => import('../pages/landings/oferta'));

// Auth (comentado)
// const Login = React.lazy(() => import('../pages/auth/Login'))
// const SignUp = React.lazy(() => import('../pages/auth/Signin'))

const loading = () => <div className="loading">Carregando...</div>;

type LoadingComponentProps = {
    component: React.LazyExoticComponent<React.FC>;
};

const LoadComponent = ({ component: Component }: LoadingComponentProps) => {
    return (
        <Suspense fallback={loading()}>
            <Component />
        </Suspense>
    );
};

const AllRoutes = () => {
    return useRoutes([
        {
            path: '/',
            element: <Root />,
            children: [
                {
                    index: true, // Define Index2 como página inicial
                    element: <LoadComponent component={Index2} />,
                },
                {
                    path: 'landing',
                    element: <LoadComponent component={Home} />,
                },
                
                { path: 'oferta', element: <LoadComponent component={Oferta} /> },
                { path: 'trial', element: <LoadComponent component={Trial} /> },
            ],
        },
        // Outras rotas fora de Root, se necessário
    ]);
};

export default AllRoutes;
