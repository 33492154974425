import React from "react";
import { Outlet } from "react-router-dom";

const Root = () => {
  return (
    <div>
      {/* Exemplo de cabeçalho */}
      <header>
        
      </header>

      <main>
        <Outlet />
      </main>

      {/* Exemplo de rodapé */}
      <footer>
      </footer>
    </div>
  );
};

export default Root;
